<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="onSubmit"
  >
    <v-card
      height="100%"
      flat
    >
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">{{ $t("Ajouter un order") }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>

      <v-card-text
        class="overflow-y-auto"
        style="max-height: 600px;"
      >
        <v-row class="pt-5">
          <v-col
            cols="12"
            sm="12"
          >
            <date-picker
              v-model="proposition"
              :max-date="null"
              :label="$t('Date de proposition')"
            />
          </v-col>

        </v-row>
      </v-card-text>
      <v-card-actions>
        <slot name="form-actions">
          <slot name="form-cancel">
            <v-btn
              :loading="loading"
              color="secondary"
              outlined
              type="reset"
              @click="dialog = false"
            >
              {{ $t('Annuler') }}
            </v-btn>
          </slot>
          <v-spacer />
          <v-btn
            :loading="loading"
            color="primary"
            class="me-3"
            type="submit"
          >
            {{ $t("Enregistrer") }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import {
  mdiClose, mdiPencilOutline, mdiPencilOffOutline, mdiCalendar,
} from '@mdi/js'
import { getIndex, getItem } from '@core/utils'
import { computed, ref, watch } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import axios from '@axios'
import { format, parse } from 'date-fns'
import store from '@/store'
import controller from './Controller'
import authorsController from '@/views/apps/author/author-list/useAuthorsList'
import DatePicker from '@/components/DatePicker'

export default {
  components: { DatePicker },
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
    withPassword: {
      type: Boolean,
      default: true,
    },
    roleOptions: {
      type: Array,
      default: () => [],
    },
    planOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const {
      form,
      isSnackbarBottomVisible,
      snackBarMessage,
    } = controller()
    const {} = authorsController()
    const errorMessages = ref({ })
    const valid = ref(false)
    const modalReceipt = ref(false)
    const modalResponse = ref(false)
    const modalProposition = ref(false)
    const isFormAuthorActive = ref(false)
    const loading = ref(false)
    const isLoadingOrders = ref(false)
    const authors = ref([])
    const fees = ref([])
    const isLoadingAuthors = ref(false)
    const search = ref(null)
    const minDate = new Date().toISOString().substr(0, 10)
    const proposition = ref(null)
    const validate = () => {
      form.value.validate()
    }
    const items = computed({
      get: () => props.selected,
      set: value => emit('update:object', value),
    })
    const formactive = computed({
      get: () => props.isFormActive,
    })

    // const item = ref(JSON.parse(JSON.stringify(blankItem)))
    const resetItem = () => {
      // item.value = {}

      // emit('update:object', item.value)
    }
    const formatDate = (date, f) => format(date, f)
    const onSubmit = () => {
      loading.value = true
      axios.post('/orders/update_multiple', { ids: items.value.map(ele => ele.id), date: proposition.value, action: 'proposal_date' }).then(response => {
        loading.value = false
        proposition.value = null
        emit('refetch-data')
      }).then(() => {
        loading.value = false
      })
    }
    watch(formactive, (val) => {
      proposition.value = null
      loading.value = false
      console.log('proposition', proposition.value)
    })

    return {
      minDate,
      resetItem,
      form,
      isFormAuthorActive,
      isLoadingOrders,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      search,
      authors,
      isLoadingAuthors,
      onSubmit,
      modalReceipt,
      modalProposition,
      modalResponse,
      formatDate,
      proposition,
      valid,
      loading,
      validate,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
        mdiCalendar,
      },
    }
  },
}
</script>
