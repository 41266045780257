<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="onSubmit"
  >
    <v-card
      height="100%"
      flat
    >
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">{{ $t("Ajouter une commande") }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>

      <v-card-text
        class="overflow-y-auto"
        style="max-height: 600px; min-height: 600px"
      >
        <v-row class="pt-5">
          <v-col
            cols="12"
            id="publicationId"
          >
            <v-autocomplete

              v-model="item.publication_id"
              :rules="[validators.required]"
              :error-messages="errorMessages.publication_id"
              :label="$t('Dossier')"
              item-text="id"
              item-value="id"
              outlined
              :multiple="false"
              dense
              hide-details="auto"
              :items="publications"
              :loading="isLoadingPublications"
              :search-input.sync="searchP"
              clearable
            >
              <template #item="{ item }">
                <div @click="setObject(item)">
                  <span>{{ item.id }} - {{ item.author.full_name }} <span v-if="item.title && item.title.length"> - {{ item.title || ''}}</span></span>
                </div>
<!--                   <div class="">
                     <v-list-item >
                       <v-list-item-content>
                         <v-list-item-title>{{ item.id }} - {{ item.author.full_name }}</v-list-item-title>
                         <v-list-item-subtitle>{{ item.manuscript?item.manuscript.title: '' }}</v-list-item-subtitle>
                       </v-list-item-content>
                     </v-list-item>

&lt;!&ndash;                     <div>{{ item.id }} - {{ item.author.full_name }}</div>
                     <div>{{ item.manuscript?item.manuscript.title: '' }}</div>&ndash;&gt;
                   </div>-->
              </template>
              <template #selection="{ item }">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.id }} - {{ item.author.full_name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.title || '' }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
</v-row>
        <template v-if="item.publication && item.publication.id">
        <v-row >
        <v-col cols="12">
          <v-card flat outlined>
            <v-card-title>{{ $t("Details de la commande") }}</v-card-title>
           <v-card-text>
             <v-row>
               <v-col
                 cols="12"
                 sm="12"
                 md="6"
               >
                 <date-picker
                   v-model="item.order_date"
                   :label="$t('Date')"
                 />
               </v-col>
               <v-col
                 cols="12"
                 sm="12"
                 md="6"
               >
                 <date-picker
                   v-model="item.expedition_date"
                   :label="$t('Expédition')"
                 />
               </v-col>

               <v-col
                 cols="12"
                 sm="12"
                 md="4"
               >
                 <v-autocomplete
                   v-model="item.destination_id"
                   :label="$t('Destination')"
                   :rules="[validators.required]"
                   :error-messages="errorMessages.destination_id"
                   :items="$store.state['app'].destinations"
                   item-text="name"
                   item-value="id"
                   outlined
                   dense
                   :hide-details="true"
                   clearable
                 >
                 </v-autocomplete>
               </v-col>
               <v-col
                 cols="12"
                 sm="12"
                 md="4"
               >
                 <v-autocomplete
                   v-model="item.printer_id"
                   :label="$t('Imprimeur')"
                   :error-messages="errorMessages.printer_id"
                   :items="$store.state['app'].printers"
                   item-text="name"
                   item-value="id"
                   outlined
                   dense
                   :hide-details="true"
                   clearable
                 >
                 </v-autocomplete>
               </v-col>
               <v-col
                 cols="12"
                 sm="12"
                 md="4"
               >
                 <v-text-field
                   v-model="item.order_reference"
                   outlined
                   dense
                   :error-messages="errorMessages.order_reference"
                   :label="$t('Numéro commande')"
                   :placeholder="$t('Numéro commande')"
                   hide-details="auto"
                 ></v-text-field>
               </v-col>
               <v-col
                 cols="12"
                 sm="12"
                 md="6"
               >
                 <v-text-field
                   type="number"
                   v-model="item.quantity"
                   outlined
                   dense
                   :error-messages="errorMessages.quantity"
                   :label="$t('Quantité')"
                   :placeholder="$t('Quantité')"
                   hide-details="auto"
                 ></v-text-field>
               </v-col>
               <v-col
                 cols="12"
                 sm="12"
                 md="6"
               >
                 <v-text-field
                   v-model="item.observation"
                   outlined
                   dense
                   :error-messages="errorMessages.observation"
                   :label="$t('Enseigne')"
                   :placeholder="$t('Enseigne')"
                   hide-details="auto"
                 ></v-text-field>
               </v-col>
               <v-col
                 cols="12"
                 sm="12"
                 md="6"
               >
                 <v-text-field
                   v-model="item.destinataire"
                   outlined
                   dense
                   :error-messages="errorMessages.destinataire"
                   :label="$t('Destinataire')"
                   :placeholder="$t('Destinataire')"
                   hide-details="auto"
                 ></v-text-field>
               </v-col>
             </v-row>
           </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card flat outlined>
            <v-card-title>{{ $t("Details d'auteur") }}</v-card-title>
            <v-card-text>
              <v-row>
                <!--            <v-col
                              cols="12"
                              sm="12"
                              md="6"
                            >
                              <v-text-field
                                v-if="item.publication && item.publication.manuscript"
                                v-model="item.publication.manuscript.title"
                                outlined
                                dense
                                :error-messages="errorMessages.title"
                                :label="$t('Titre')"
                                :placeholder="$t('Titre')"
                                readonly
                                hide-details="auto"
                              ></v-text-field>
                              <span v-else>{{ $t("Pas de manuscript") }}</span>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                              md="6"
                            >
                              <v-autocomplete
                                v-model="item.publication.author_id"
                                :error-messages="errorMessages.author_id"
                                :label="$t('Auteur')"
                                item-text="full_name"
                                item-value="id"
                                readonly
                                outlined
                                dense
                                hide-details="auto"
                                :items="authors"
                                :loading="isLoadingAuthors"
                                :search-input.sync="search"
                                clearable
                              >
                                <template #item="{ item }">
                                  <AvatarName :options="{avatar: item.avatar, label: item.full_name }" />
                                </template>
                                <template #selection="{ item }">
                                  <AvatarName :options="{avatar: item.avatar, label: item.full_name }" />
                                </template>
                                <template #no-data>
                                  <span>{{ $t("L'auteur.") }} <strong>{{ search }}</strong> {{ $t("n'existe pas.") }}</span>
                                  <v-btn
                                    depressed
                                    small
                                    color="primary"
                                    @click="newAuthor"
                                  >
                                    <span>{{ $t("Créer l'auteur") }}</span>
                                  </v-btn>
                                </template>
                              </v-autocomplete>
                              <v-dialog
                                v-model="isFormAuthorActive"
                                touchless
                                :right="!$vuetify.rtl"
                                :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
                              >
                                <author-form
                                  v-model="item.author"
                                  :is-form-active="isFormAuthorActive"
                                  :with-addresses="false"
                                  @refetch-author="updateAuthors"
                                >
                                  <template v-slot:form-title>
                                    <span class="font-weight-semibold text-base text&#45;&#45;primary">{{ item.id ? $t('Modifier'): $t('Ajouter un auteur') }}</span>
                                    <v-spacer />
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      icon
                                      small
                                      @click="isFormAuthorActive = !isFormAuthorActive"
                                    >
                                      <v-icon size="22">
                                        {{ icons.mdiClose }}
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <template #form-cancel>
                                    <v-btn
                                      color="secondary"
                                      outlined
                                      type="reset"
                                      @click="isFormAuthorActive = !isFormAuthorActive"
                                    >
                                      {{ $t('Annuler') }}
                                    </v-btn>
                                  </template>
                                </author-form>
                              </v-dialog>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                              md="6"
                            >
                              <v-text-field
                                v-model="item.publication.isbn"
                                outlined
                                dense
                                :error-messages="errorMessages.isbn"
                                :label="$t('ISBN')"
                                :placeholder="$t('ISBN')"
                                readonly
                                hide-details="auto"
                              ></v-text-field>
                            </v-col>-->
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <v-text-field
                    v-model="item.address"
                    outlined
                    dense
                    :error-messages="errorMessages.address"
                    :label="$t('Adresse')"
                    :placeholder="$t('Adresse')"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <v-text-field
                    v-model="item.address_line2"
                    outlined
                    dense
                    :error-messages="errorMessages.address_line2"
                    :label="$t('Complément d’adresse')"
                    :placeholder="$t('Complément d’adresse')"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                >
                  <v-text-field
                    v-model="item.postcode"
                    outlined
                    dense
                    :error-messages="errorMessages.postcode"
                    :label="$t('Code postal ')"
                    :placeholder="$t('Code postal ')"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                >
                  <v-text-field
                    v-model="item.city"
                    outlined
                    dense
                    :error-messages="errorMessages.city"
                    :label="$t('Ville')"
                    :placeholder="$t('Ville')"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                >
                  <v-text-field
                    v-model="item.phone"
                    outlined
                    dense
                    :error-messages="errorMessages.phone"
                    :label="$t('Téléphone')"
                    :placeholder="$t('Téléphone')"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <v-combobox
                    v-model="item.email"
                    outlined
                    type="email"
                    :label="$t('Mail')"
                    :placeholder="$t('Mail')"
                    :error-messages="errorMessages.email"
                    dense
                    hide-details="auto"
                    multiple
                    small-chips
                    clearable
                    clearable-chips
                  >
                    <template v-slot:selection="{ attrs, item, parent, selected }">
                      <v-chip
                        v-bind="attrs"
                        label
                        small
                      >
                            <span class="pr-2">
                              {{ item }}
                            </span>
                        <v-icon
                          small
                          @click="parent.selectItem(item)"
                        >
                          $delete
                        </v-icon>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="item.country_id"
                    :label="$t('Pays')"
                    :error-messages="errorMessages.country_id"
                    :items="$store.state['app'].pays"
                    item-text="full_name"
                    item-value="id"
                    outlined
                    dense
                    hide-details="auto"
                    clearable
                  >
                  </v-autocomplete>
                </v-col>

              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        </v-row>
</template>

      </v-card-text>
      <v-divider class="my-5" />
      <v-card-actions>
        <slot name="form-actions">
          <slot name="form-cancel">
            <v-btn
              :loading="loading"
              color="secondary"
              outlined
              type="reset"
              @click="dialog = false"
            >
              {{ $t('Annuler') }}
            </v-btn>
          </slot>
          <v-spacer />
          <v-btn
            :loading="loading"
            color="primary"
            class="me-3"
            type="submit"
          >
            {{ $t("Enregistrer") }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"

      dark
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </v-form>
</template>

<script>
import {
  mdiClose, mdiPencilOutline, mdiPencilOffOutline, mdiCalendar,
} from '@mdi/js'
import { getIndex, getItem } from '@core/utils'
import { computed, ref, watch } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import axios from '@axios'
import { format, parse } from 'date-fns'
import store from '@/store'
import controller from './Controller'
import authorsController from '@/views/apps/author/author-list/useAuthorsList'
import AvatarName from '@/components/AvatarName'
import AuthorForm from '@/views/apps/author/author-list/AuthorForm'
import useAuthorsList from '@/views/apps/author/author-list/useAuthorsList'
import DatePicker from '@/components/DatePicker'

export default {
  components: { DatePicker, AuthorForm, AvatarName },
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Object,
      default: () => {},
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
    withPassword: {
      type: Boolean,
      default: true,
    },
    roleOptions: {
      type: Array,
      default: () => [],
    },
    planOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const {
      form,
      isSnackbarBottomVisible,
      snackBarMessage,
    } = controller()
    const {} = authorsController()
    const errorMessages = ref({ })
    const valid = ref(false)
    const modalReceipt = ref(false)
    const modalResponse = ref(false)
    const modalProposition = ref(false)
    const isFormAuthorActive = ref(false)
    const loading = ref(false)
    const isLoadingOrders = ref(false)
    const authors = ref([])
    const publications = ref([])
    const fees = ref([])
    const isLoadingAuthors = ref(false)
    const search = ref(null)
    const searchP = ref(null)
    const minDate = new Date().toISOString().substr(0, 10)
    const isLoadingPublications = ref(false)

    const validate = () => {
      form.value.validate()
    }

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })
    if (item.value && item.value.author) {
      item.value.author_id = item.value.author.id
      if (authors.value.findIndex(ele => ele.id === item.author_id) < 0) {
        authors.value.push(item.value.author)
      }
    }

    watch(item, val => {
      if (item.value && item.value.author && authors.value.findIndex(ele => ele.id === item.author_id) < 0) {
        item.value.author_id = item.value.author.id
        if (authors.value.findIndex(ele => ele.id === item.author_id) < 0) {
          authors.value.push(item.value.author)
        }
      }
    }, { deep: true })

    // const item = ref(JSON.parse(JSON.stringify(blankItem)))
    const resetItem = () => {
      // item.value = {}

      // emit('update:object', item.value)
    }
    const formatDate = (date, f) => format(date, f)
    const newAuthor = () => {
      item.value.author = {
        full_name: search.value, email: null, phone: null, addresses: [],
      }
      isFormAuthorActive.value = true
    }
    const searchAuthors = term => {
      store.dispatch('app/searchAuthors', { term, rowsPerPage: 100 }).then(response => {
        isLoadingAuthors.value = false
        for (let i = 0; i < response.data.data.length; i++) {
          const u = response.data.data[i]
          const indexU = authors.value.findIndex(el => el.id === u.id)
          if (indexU < 0) {
            authors.value.push(u)
          }
        }
      })
    }
    const searchPublications = term => {
      axios
        .get('/task/publications', { params: { term, rowsPerPage: 10, with_addresses: true } })
        .then(response => {
          isLoadingPublications.value = false
          for (let i = 0; i < response.data.length; i++) {
            const p = response.data[i]
            const indexU = publications.value.findIndex(el => el.id === p.id)
            if (p.id && indexU < 0) {
              publications.value.push(p)
            }
          }
        }).then(() => {
          isLoadingPublications.value = false
        })
    }
    axios.get('/fees').then(response => {
      fees.value = response.data.data
    })
    const updateAuthors = author => {
      isFormAuthorActive.value = false
      authors.value.push(author)
      item.value.author = author
      item.value.author_id = author.id
    }

    const onSubmit = () => {
      if (valid.value) {
        /* if (!item.value.receipt_date) {
          isSnackbarBottomVisible.value = true
          snackBarMessage.value = 'Veuillez entrer la date de réception'

          return false
        } */
        loading.value = true
        store.dispatch('app-order/addItem', item.value)
          .then(response => {
            loading.value = false
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                errorMessages.value = { ...response.data.errors }
              } else {
                isSnackbarBottomVisible.value = true
                snackBarMessage.value = response.data.message

                // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
              }

              return true
            }
            resetItem()

            // emit('update:is-form-active', false)

            emit('refetch-data')
          }).catch(error => {
            loading.value = false

            if (error.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = { ...error.data.errors }
            }
          })
      } else {
        validate()
      }
    }

    //searchAuthors('')
    //searchPublications('')

    // onMounted(searchParticular)
    watch(search, val => {
      if (!val || isLoadingAuthors.value) return ''
      isLoadingAuthors.value = true
      searchAuthors(val)

      return ''
    })
    var timeout  = null
    watch(searchP, val => {
      if (timeout) {
        clearTimeout(timeout)
      }
      timeout = setTimeout(() => {
        if (!isLoadingPublications.value) {
          if (!val || isLoadingPublications.value) return ''
          isLoadingPublications.value = true
          searchPublications(val)

        }
      }, 1500)

      return ''
    })
    const nbPages = computed({
      get: () => item.value.sum_pages,

      /* set: value => emit('update:object', value), */
    })
    const nbWords = computed({
      get: () => item.value.sum_words,
    })
    const publicPrice = computed({
      get: () => item.value.public_price,
    })
    const publicationIdChanged = computed({
      get: () => item.value.publication_id,
    })
    const calculateDiscount = () => {
      if (!item.value.custom_discount) {
        item.value.discount_price = Math.ceil(item.value.public_price * 0.7) + 1
      }
    }

    const calculatePrice = () => {
      const fee = fees.value.find(i => i.page_min <= nbPages.value && i.page_max >= nbPages.value)

      if (fee && (nbPages.value)) {
        const price = parseFloat(fee.frais)
        const sub = parseInt(nbPages.value - fee.subtraction, 10)
        item.value.public_price = parseFloat((price + parseFloat((sub * fee.percentage).toFixed(2))).toFixed(2))
      } else {
        item.value.public_price = store.state.app.settings.default_pages_price || 27
      }
    }
    const calculatePages = () => {
      // item.value.sum_pages = null
      if (!item.value.custom_pages && item.value.sum_words >= 0) {
        const words = store.state.app.settings.number_words_per_page || 299
        item.value.sum_pages = Math.ceil(item.value.sum_words / words)
      } else {
        item.value.sum_pages = null
      }
    }


    watch(nbPages, val => {
      calculatePrice()

      return ''
    })
    watch(nbWords, val => {
      calculatePages()

      return ''
    })
    watch(publicPrice, val => {
      calculateDiscount()

      return ''
    })



    const setObject = val => {
      item.value.publication = val
      item.value.publication_id = val.id

      item.value.title = null
      item.value.title = val.title || ''
      item.value.address = null
      item.value.author_id = null
      item.value.manuscript_id = null
      item.value.email = null
      item.value.phone = null
      item.value.country_id = null
      item.value.address = null
      item.value.address_line2 = null
      item.value.city = null
      item.value.postcode = null
      if (val.author) {
        authors.value.push(val.author)
        item.value.author = val.author
        item.value.author_id = val.author_id
        item.value.manuscript_id = val.manuscript_id
        item.value.email = val.author.email || []
        item.value.phone = val.author.phone
        item.value.country_id = val.author.country_id
        item.value.country_id = val.author.country_id
        if (val.author.addresses && val.author.addresses.length) {
          item.value.address = val.author.addresses[0].address
          item.value.address_line2 = val.author.addresses[0].address2
          item.value.city = val.author.addresses[0].city
          item.value.postcode = val.author.addresses[0].postal_code
        }
      }
    }

    watch(publicationIdChanged, val => {
      /* item.value.country_id = authors.value.find(i => i.id === val).country_id*/
      setObject(publications.value.find(i => i.id === val))

      return ''
    })

    store
      .dispatch('app/fetchSources', { per_page: 500 })
    store
      .dispatch('app/fetchCountries', { per_page: 500 })

    return {
      minDate,
      resetItem,
      form,
      isFormAuthorActive,
      isLoadingOrders,
      isLoadingPublications,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      search,
      searchP,
      setObject,
      authors,
      publications,
      isLoadingAuthors,
      onSubmit,
      updateAuthors,
      modalReceipt,
      modalProposition,
      modalResponse,
      formatDate,
      newAuthor,
      item,
      valid,
      loading,
      validate,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
        mdiCalendar,
      },
    }
  },
}
</script>
<style lang="scss">

</style>
